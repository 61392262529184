import React, { FC, PropsWithChildren } from "react";
import GlobalStyle from "./styles/global";
import { BreakpointOverrides, ThemeProvider, createTheme } from "@mui/material";
import breakpoints from "@/styles/breakpoint";

const values: BreakpointOverrides = {};

for (const key in breakpoints) {
  if (Object.hasOwn(breakpoints, key) && typeof window !== "undefined") {
    const value = breakpoints[key as keyof typeof breakpoints];
    (values as any)[key] = parseInt(value);
  }
}

const theme = createTheme({
  breakpoints: {
    values: values as any,
  },
});

const Global: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default Global;
